@import "../../sass/variables";
@import "../../sass/mixins";

.menu{
  z-index:2;
}
.menu:hover{
  box-shadow:0px 2px 20px 0px rgba(0,0,0,0.75) !important;
}
input:focus {

  outline: none;
}
.outer__div{
  height:58vw;
}
.desc__div{
  width:45%;
  display:inline-block;
  float:left;
  margin-top:170px;
  margin-left:5.85vw;

p span,a span{
  color:#1E957C;
}
}
.image___div{
width:40%;
display:inline-block;
right:0;
text-align:right;
margin-top:100px;
margin-right:5.85vw;
float:right;
img{
    width:100%;
}
}
.form{
  width:50%;
  margin:0 auto;
  background-color: white;
  text-align: center;
  height:36vw;
}

.heading__text{
  padding-left: 80px;
  font-size: 34px;
}
.form__heading{
  font-size: 3vw;
  margin-bottom: 30px;
}
.description{
  margin: 20px 0 0 80px;
  font-size: 20px;
  color: grey;
}
.input__fieldOne{
  border: 0;
  width: 45%;
  font-size: 22px;
  padding: 10px;
  border-bottom: 3px solid #1E957C;
  margin:30px 0 20px 0;
}

.input__fieldTwo{
  border: 0;
  padding: 10px;
  width: 45%;
  font-size: 22px;
  border-bottom: 3px solid #1E957C;
  margin:30px 0 20px 30px;
}
.input__button{
  width:25%;
  font-size: 23px;
  z-index: 1;
  text-align: right;
  position: relative;
  display: inline-block;
  color: #1E957C;
  background-color:white ;
  border-width: 0;
  outline:none;
  margin-top:30px
}
.modal{
  background-color:white;
  outline:none;
  width:450px;
  margin-top: -125px;
  margin-left: -225px;
  height:250px;
  position: absolute;
  top:50%;
  left:50%;
}
.modal__text{
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  text-align: center;
}

@media(max-width:1000px){
  .outer__div{
    height:1150px;
  }
  .desc__div{
    width:100%;
      margin-left:0;
  }
  .heading__text{
    font-size: 40px;
    padding-right: 80px;
  }
  .description{
  font-size:  24px;
  padding-right: 80px;
  }
  .form__heading{
    font-size: 30px;
  }
  .input__fieldOne{
    padding: 5px;
    width: 100%;
    font-size: 16px;
    margin:10px 0 20px 0;
  }

  .input__fieldTwo{
    padding: 5px;
    width: 100%;
    font-size: 16px;
    margin:10px 0 20px 0;
  }
  .image___div{
    width:100%;
    margin:20px auto;
    text-align: center;
    img{
      width:80%
    }
  }
  .input__button{
    font-size: 18px;
  }


}
@media(max-width:685px){
  .outer__div{
    height:1080px;
  }

}
@media(max-width:575px){
  .outer__div{
    height:1050px;
  }
  .heading__text{
    font-size: 36px;
  }
  .description{
  font-size:  22px;
  }

}
