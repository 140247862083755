.portal{
    color: black;
    text-decoration: none;
    font-size: 28px;
    position:absolute;
    top:160px;
    width:200px;
    left: 50%;
    margin-left: -100px;
    text-align: right;
}
.portal:hover{
    color:#25937c;
}

.tendering{
    color: black;
    text-decoration: none;
    font-size: 28px;
    width:200px;
    position:absolute;
    top:220px;
    text-align: right;
    left: 50%;
    margin-left: -100px;
}

.tendering:hover { color:#25937c; }
