@import "../../../sass/mixins";

.racking{
  width: 100%;
  &__heading{
    text-align: center;
    margin: 1rem auto;
    font-weight: 100;
    text-transform: uppercase;
  }

  &__form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    &--manufacturer{
      grid-column-start: 1;
      grid-column-end: 2;

      & input { width :100% ; }
    }

    @include formfields;

    .textfield{
      & .select-search__select {
        & ul {
          width: 200px;
        }
      }
    }
  }

  &__back{
    @include btn(false);
    display: inline-block;
    margin: 6rem 0.5rem 1rem 0.5rem;
  }
  &__next{
    @include btn(true);
    display: inline-block;
    margin: 6rem 0.5rem 1rem 0.5rem;
  }
}
@media(max-width:768px){
  .racking{

    &__form{
      .select-search__value{
        input{
          width: 100%;
        }
      }
    }
  }
}

@media(max-width:634px){
  .racking{
    width: 100%;
    &__heading {
      text-align: center;
      width: 200px;
      margin: 1rem auto;
    }

    &__form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__next,
    &__back{
      display: block;
      margin: 1rem auto;
    }
  }
}
