@import "../../../sass/variables";
@import "../../../sass/mixins";

.compare{
  max-width: 80% ;
  margin: 0 auto 2rem auto ;
  background-color: $background-color;
  border-radius: 10px;
  overflow-x: auto;

  &__heading{
    text-align: center;
    margin: 2rem 0;
  }

  &__next{
    @include btn(true);
    margin: 2rem;
  }
}

.add{

  &__container{
     position: relative;
     width: 80%;
     height: 23px;
     margin: 2rem auto 0 auto;
  }
  &__line{
    border: 13px solid black;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    background-color: black;
    top:0;left:0;width:100%;height:100%;
  }
  &__circleOne{
    background-color: $primary-color;
    z-index: 1;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 8px;
    left: 13px;
    border-radius: 50%;

  }
  &__circleTwo{
    background-color: #6c757d;
    z-index: 1;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 8px;
    left: 27px;
    border-radius: 50%;
  }

}


@media(max-width:768px){
  .compare{
    max-width: 700px;
  }
}

@media(max-width:500px){
  .compare{
    width: 300px;

    &__next{
      @include btn(true);
      margin: 1rem auto;
    }
  }
}
