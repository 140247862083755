// General Classes
@import "./sass/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;

}

h1{
  font-family: 'Manrope' ,sans-serif;
  font-weight: normal;
}
