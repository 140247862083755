@import "../../sass/variables";

.add{

  &__containerTwo{
    width:100%;
    height: 23px;
    position: fixed;
    bottom: 0;
    left: 0;

  }
  &__lineTwoContainer{
    width: 30%;
    display: inline-block;
  }

  &__lineTwo{
    border: 13px solid black;
    border-radius: 0 0 10px 10px;
    position: absolute;
    background-color: black;
    top:0;left:0;width:100%;height:100%;
  }

 &__userDetails{
    z-Index: 1;
    position: relative;
    left: 40px;
    display: inline-block;
    color: white;
 }
 &__verticalLine{
    z-index: 1;
    position: relative;
    left: 50px;
    display: inline-block;
    color: white;
 }
 &__description{
    z-index:  1;
    position: relative;
    display: inline-block;
    left: 63px;
    color: $primary-color;
 }
 &__logout{
    z-index: 1;
    width: 60%;
    text-align: right;
    position: relative;
    display: inline-block;
    color: $primary-color;
 }
 &__logoutButton{
    background-color: #000;
    color: $primary-color;
    border-width: 0;
 }

}



.home__nav{
  width: 100%;

  &__logo{
    height: 40px;
    width: 140px;
    margin: 0.5rem 0 0 0.5rem;

    img{
      height: 100%;
      width: 100%;
    }
  }

  &__links{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    a{
      text-align: center;
      font-size: 1.5rem;
      text-decoration: none;
      margin: 0.5rem 1rem ;
      color: $secondary-color;
      position: relative;
        font-weight: 100;

      &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: #000;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }

      &:hover:after {
        width: 100%;
        left: 0;
      }
    }
  }
  .active{
    color: #000;

  }
}

@media(max-width:500px){
  .home__nav{

    &__logo{
      height: 20px;
      width: 70px;
      margin: 0.5rem 0 0 0.5rem;
    }

    &__links{
      flex-direction: column;
      a {
        font-size: 1rem;
        margin: 0.25rem  ;
      }
    }
  }
}
