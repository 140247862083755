@import "../../../sass/mixins";


.categories{
  margin: 0 auto 1rem auto;
  width: 80%;
  height: 380px;
  border-radius: 0 0 10px 10px;
  background-color: $background-color;
  padding: 1.5rem;

}

.button__form{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width:80%;
  margin:0 auto;
  padding: 0 1.5em;

}
.nextbutton__form , .backbutton__form{
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 0 auto;

}
 .next-button-text{
   text-align: left;
   margin-top: 3px;
 }
 .next-button-icon{
   text-align:right;
   font-size: 30px;
 }
.roof{
  width: 100%;
  &__heading{
    text-align: center;
    margin: 1rem auto;
    font-weight: 100;
    text-transform: uppercase;
  }

  &__form{
    display: grid;
    grid-template-columns: 1fr 1fr;

    &--manufacturer{
      grid-column-start: 1;
      grid-column-end: 3;


    }

    @include formfields;
  }

  &__next{
    @include btn(true);
  width:60%;
  margin: 40px 0.5rem 70px auto;
  padding: 0.5rem;


  }
  &__back{
    @include btn(false);
  width:60%;
  margin: 40px auto 70px 0.5rem;
  padding: 0.5rem;

  }

}

@media(max-width:768px){
  .button__form{
max-width: 700px;
  }


  .categories{
    max-width: 700px;
  }

  .roof{
    &__heading{
      text-align: center;

      margin: 1rem auto;
    }

    &__form{
      &--manufacturer{
        input {
        width : 100% ;
        margin: 0 auto;
        }



      }
    }
  }
}

@media(max-width:634px){
.categories{
      width: 300px;
      height: 100%;
      padding: 0.75rem;
    }

    .button__form{
      width: 300px;
    }


  .roof{
    width: 100%;
    &__heading{
      text-align: center;
      width: 200px;
      margin: 1rem auto;
    }

    &__form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__next{
      margin: 10px 0 20px auto;
    }
    &__back{
      margin: 10px auto 20px 0;
    }
  }
}
