@import "../../../sass/variables";

.add{

  &__stepper{
    margin: 0 auto;
    max-width: 800px;
  }

  &__container{
     position: relative;
     width: 80%;
     height: 23px;
     margin: 0 auto;
  }
  &__line{
    border: 13px solid black;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    background-color: black;
    top:0;left:0;width:100%;height:100%;
  }
  &__circleOne{
    background-color: $primary-color;
    z-index: 1;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 8px;
    left: 13px;
    border-radius: 50%;

  }
  &__circleTwo{
    background-color: #6c757d;
    z-index: 1;
    position: absolute;
    width: 8px;
    height: 8px;
    top: 8px;
    left: 27px;
    border-radius: 50%;
  }

}

@media(max-width:768px){
  .add{

    &__stepper{
      max-width: 600px;
    }

    &__container{

       max-width: 700px;

    }
  }
}

@media(max-width:634px){
  .add{

    &__stepper{
      margin: 0 auto;
    }

    &__container{
       width: 300px;

    }
  }
}
