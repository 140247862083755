@import "../../../sass/mixins";

.panel{
  width: 100%;
  &__heading{
    text-align: center;
    margin: 1rem auto;
    font-weight: 100;
    text-transform: uppercase;
  }


  &__form{
    display: grid;
    grid-template-columns: 1fr 1fr;

    &--manufacturer{
      grid-column-start: 1;
      grid-column-end: 2;

      & input { width : 100%; }
    }

    @include formfields;
  }

  &__back{
    @include btn(false);
    display: inline-block;
    margin: 1rem 0.5rem ;
  }

  &__next{
    @include btn(true);
    display: inline-block;
    margin: 1rem 0.5rem;
  }
}

.back-button-icon{
    text-align: left;
    font-size: 30px;
  }

.back-button-text{
    text-align:right;
    margin-top:3px;

}

@media(max-width:768px){
  .panel{

    &__form{
      &--manufacturer{
        input {
        width : 100% ;
        margin: 0 auto;
        }
      }
    }
  }
}

@media(max-width:634px){
  .panel{
    width: 100%;
    &__heading{
      text-align: center;
      width: 200px;
      margin: 1rem auto;
    }

    &__form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__back,
    &__next{
      margin: 1rem auto;
      margin: 1rem auto;
    }
  }
}
