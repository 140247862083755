@import "../../sass/variables";
@import "../../sass/mixins";

.login{
  background-color: $background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo{
    height: 100px;
    width: 350px;
    margin : 4rem 0;
    
    img{
      height: 100%;
      width: 100%;
    }
  }

  &__container{
    max-width: 1200px;
    width: 45%;
    &__heading{
      align-self: start;
      margin: 0.5rem;
    }

    &__form{

      &__group{
        margin-bottom: 1.5rem;
        display: flex;
        border-radius: 10px;
        
        &__icon{
          background-color: #000;
          color: $white;
          padding: 0.75rem 2rem;
          border-radius:  10px 0 0 10px ;
          font-size: 1.5rem;
        }
        & input{
          border: none;
          padding: 0.75rem 1rem;
          border-radius: 0 10px 10px 0;
          width: 100%;
          font-size: 1.5rem;
          outline: none;
        }
      }
      &__forget{
        color: $secondary-color;
        text-decoration: none;
        display: block;
        margin-left: 2rem;
      }
      &__btn{
        @include btn(true)
      }
    }
  }
}

// Media Querries

@media(max-width:576px){
  .login{

    &__logo{
      height: 50px;
      width: 175px;
    }

    &__container{

      &__heading{
        font-size: 1.5rem;
      }
    
      &__form{
  
        &__group{
          margin-bottom: 1rem;
          
          &__icon{
            padding: 1rem;
            font-size: 1rem;
          }
          & input{
            padding: 0.5rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}