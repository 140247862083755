.packages {
  max-width: 80%;
  margin: 0 auto 6rem auto;
  background-color: #eaf2f0;
  border-radius: 10px;
  color: black;
  overflow-x: auto;
  &__heading{
  text-align: center;
  margin: 2rem;}
}
.vl {
  border-left: 2px solid grey;
  height: calc(480px - 9vw);
  display: inline-block;
  position: absolute;
  margin-top: 60px;
}
.outer_div{
  width: 95%;
  margin: 0 0 0 3.90vw;
}

.div {
  width: 31%;
  height: 450px;
  margin-top: 10px;
  position: relative;
  display: inline-table;
  padding: 0.8vw;
}
.inner_div{
  width: 100%;
  position: relative;
}
.line {
  border-radius: 10px 10px 0px 0px;
  background-color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
}
.heading {
  z-index: 1;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 3px;
  font-size: 20px;
  display: inline-block;
  color: white;
}
.text {
  width: 80%;
  margin: 30px auto;
  text-align: center;
  position: relative;
  height:175px;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
}
.fee{
  width: 80%;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

@media(max-width:1030px){
.text{
  font-size: 14px;
  height: 130px;
}
.fee{
  font-size: 14px;
}
.vl{
  height: calc(390px - 9vw);
}
.div{
  height:390px;
}



}
