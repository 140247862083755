@import "../../../sass/variables";
@import "../../../sass/mixins";

.graph{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 80% ;
  margin: 0 auto 2rem auto ;
  background-color: $background-color;
  border-radius: 10px;
  &__next{
    @include btn(true);
    margin: 2rem;
  }

  &__container{
    width: 100%;
    height: 600px;
    margin-top: -10px;

  }

  &__heading{
    text-align: center;
    margin: 2rem;

  }
  &__tabs{
    width: 100%;
    &__btn{
      padding: 1rem;
      width: 50%;
      border: none;
      border-bottom : solid 3px $background-color;
      outline: none;
      background:$background-color ;
      transition: all 0.25s;
    }

    .active{
      border-bottom : solid 3px $secondary-color;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
  }
}
.outer__container
{
  width: 100%;
  margin: 0 auto;
}
.list{
  color: #686868;
  font-size: 12px;
  margin-left: 150px;
  margin-top: 30px;
}

@media(max-width:1024px){
  .graph{
    &__container{
      width: 700px;
      height: 350px;
    }
  }
}

@media(max-width:576px){
  .graph{
    &__container{
      width: 100%;
      height: 300px;
    }
  }
}
