@import "../../../sass/mixins";

.design{
  width: 100%;
  &__heading{
    text-align: center;
    margin: 1rem auto;
    font-weight: 100;
    text-transform: uppercase;
  }

  &__form{
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include formfields;
  }

  &__back{
    @include btn(false);
    display: inline-block;
    margin: 0.5rem;
  }
  &__next{
    @include btn(true);
    display: inline-block;
    margin: 0.5rem;
  }
}
  .textsize{
    font-size: 0.8rem;
  }
  @media(max-width:880px){
    .textsize{
    font-size: 0.6rem;

}  }


@media(max-width:634px){
  .design{

    &__heading {
      text-align: center;
      width: 200px;
      margin: 1rem auto;
    }

    &__form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__next,
    &__back{
      display: block;
      margin: 1rem auto;
    }
  }
}
