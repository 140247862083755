@import "../../../sass/variables";
@import "../../../sass/mixins";

.namepopup{
  background: $background-color;
  margin: 10rem auto;
  width: 400px;
  height:  200px;
  outline: none;
  border-radius: 15px;
  border: 2px $secondary-color solid ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @include formfields;
  
  &__sub{
    @include btn(true);
    padding: 0.5rem;
    font-size: 1rem;
  }
}

@media(max-width:768px){
  .namepopup{
    width: 300px;

    &__sub{
      padding: 0.3rem;
      font-size: 0.7rem;
    }
  }  
}