@import "../../../sass/mixins";
@import "../../../sass/variables";


.buy__button{
  @include btn(true);
width:60%;
font-size: 18px;
height:40px;
padding: 0;
}
.packageform{
  width:380px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -125px;
  margin-left: -160px;
  background-color: white;
  text-align: center;
  height:260px;
  border-radius:20px;
  outline:none;
}

.packageform__heading{
  font-size: 20px;
  width:80%;
  margin: 0 auto;
  padding-top: 15px;
}
.packageinput__fieldOne{
  border: 0;
  width: 35%;
  font-size: 14px;
  border-bottom: 3px solid #1E957C;
  margin:30px 0 20px 0;
}

.packageinput__fieldTwo{
  border: 0;
  width: 35%;
  font-size: 14px;
  border-bottom: 3px solid #1E957C;
  margin:30px 0 20px 30px;
}
.packageinput__button{
  width:25%;
  font-size: 16px;
  z-index: 1;
  margin-right: 22px;
  position: relative;
  display: inline-block;
  color: #1E957C;
  background-color:white ;
  border-width: 0;
  outline:none;
}
