@import "../../../sass/mixins";
@import "../../../sass/variables";


.value{
  width: 100%;
  &__heading{
    text-align: center;
    margin: 1rem auto;
    font-weight: 100;
    text-transform: uppercase;
  }

  &__form{
    display: grid;
    grid-template-columns: 32% 34% 34%;
    @include formfields;

    &__last{
      grid-column-start: 2;
      grid-column-end: 4;
      display: flex;
      justify-content: center;
      align-items: center;

      .textfield{
        width: 100%;
      }
    }
  }

  &__back{
    @include btn(false);
    display: inline-block;
    margin: 0.4rem;
  }
  &__done{
    @include btn(true);
    display: inline-block;
    margin: 0.4rem;
  }
  &__more{
    @include btn(true);
    width:80%;
    margin: 40px auto 40px auto;
    padding: 0.5rem;
    height:66px;

  }
}
.width-one-third{
  width: 33.3%;
}
.width{
  width:80%;
  height:66px;
}
@media(max-width:768px){
  .value{
    width: 100%;

    &__form{
      &__last{
        justify-content: space-around;
        .textfield{
           width: 120px;
        }
      }
    }
    &__more{
      width: 90%;
    }
  }
}
@media(max-width:634px){
  .value{

    &__heading {
      text-align: center;
      width: 200px;
      margin: 1rem auto;
    }

    &__form{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__last{
        flex-direction: column;
        .textfield{
          width: 200px;
        }
      }
    }

    &__back,
    &__done,
    &__more,
    &__or{
      display: block;
      margin: 1rem auto;
    }

    &__more{
      width: 200px;
    }

    &__or{
      text-align: center;
    }
  }
}

.valuebutton__form{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width:80%;
  margin:0 auto;
  padding: 0 1.5rem;

}
