@import "./variables";

//Primary - Secondary buttons
@mixin btn($primary) {

  text-decoration: none;
  display: block;
  width: 130px;
  text-align: center;
  padding: 0.6rem 1.8rem;
  margin: 1rem auto;
  border-radius: 10px;
  border: none;
  font-size: 1.5rem;
  outline: none;
  transition: all 0.3s;
  font-weight: 100;
  cursor: pointer;

  @if $primary {
    color: $white;
    border: 1px solid $dusty-gray;
    background-color: $primary-color;
    &:hover{
      border: 1px solid #999;
      color: #000;
      background-color: $secondary-color;
    }
  } @else {
    color: $white;
    border: 1px solid #999;
    background-color: #999;
    &:hover{
      border: 1px solid #000;
      color: #000;
      background-color: $background-color;
    }
  }

  @media(max-width:634px){
    padding: 0.4rem 1.2rem;
    font-size: 1rem;
    width: 80px;
  }
}

// Add Company => textfields , radiobuttons , iconbuttons & error
@mixin formfields {

  .textfield{
    border: 1px solid $background-color;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem;


    & label{
      font-size: 1rem;
      font-weight: 100;
    }
    & input,
    & select{
      outline: none;
      border: 1px solid $white;
      border-radius: 10px;
      padding: 0.5rem;
      font-size: 1rem;
      margin-top: 0.5rem;
        font-weight: 100;

      &:disabled{
        border: 1px solid #000;
      }
    }

    & option{
      background-color: $background-color;
      color: $secondary-color
    }

    & .select-search__select {
      position: relative;

      & ul {
        min-height: 35px;
        max-height: 105px;
        overflow-x : hidden;
        overflow-y : scroll;
        list-style: none;
        position: absolute;
        background-color: $white;
        width: 100%;
        z-index: 2;
        border: 1px solid #000;

        &::before{
          content: "No Items Found";
          position: absolute;
          z-index: -1;
          text-align: center;
          padding: 0.5rem;
        }

        & li button{
          background-color:$background-color;
          color: $secondary-color;
          width: 100%;
          outline: none;
          border: none;
          border-bottom: 1px solid #999;
          padding: 0.5rem;
          font-size: 1rem;
          &:hover{
            background-color: $secondary-color;
            color: $background-color;
          }
        }
      }
    }
  }

  .radiobutton{
    border: 1px solid $background-color;
    width: 100%;
    padding: 0.5rem;


    & label{
      display: block;
      font-size: 1rem;
      font-weight: 100;
    }

    & input{
      margin: 1rem 0.5rem 0 0.5rem;


      &:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        position: relative;
        background-color: $white;
        content: '';
        display: inline-block;
        border: 2px solid $secondary-color;

      }

      &:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: $secondary-color ;
        content: '';
        display: inline-block;
      }
    }
  }

  .iconbutton{
    border: 1px solid $background-color;
    padding: 0.5rem;
    position: relative;
      font-size: 1rem;
    & label{
      display: block;
      font-size: 1rem;
    }

    &--yes ,
    &--no{
      margin:  1rem 0.5rem 0 0.8rem;


      &::after{
        font-family: "Font Awesome 5 Free";
        font-size: 1.5rem;

        position: relative;
        background-color: $background-color;
        display: inline-block;
      }

      &::after{
        content: '\f164';
      }

      &:checked:after{
        color: $secondary-color;

      }
    }

    &--no{
      &::after{
        content: '\f165';
      }
    }
  }

  .error-border{
    width: 100%;
    border: 1px red solid;
    border-radius: 10px;
  }

  @media(max-width:880px){

    .radiobutton{
      & label{
        font-size:  0.65rem;
      }
}

    .textfield{
      width:100%;
      & .select-search__select {
          & ul{
            font-size: 0.65rem;
          & li button{

            font-size: 0.65rem;

          }
        }
        }

      & label{

        font-size: 0.65rem;
      }

      & input,select{
        font-size: 0.6rem
      }


    }
    .iconbutton{
        font-size: 0.6rem;

      & label{

        font-size: 0.6rem;
      }



      &--yes ,
      &--no{
          margin:  1rem 0.1rem 0 0.4rem;

        &::after{

          font-size: 0.9rem;

        }




      }

      &--no{
        &::after{
          content: '\f165';
        }
      }
    }
  }


  @media(max-width:634px){

    .textfield{
      & .select-search__select {
        & ul {
          width: 200px;
        }
      }
    }



    .radiobutton{
      & label{
        text-align: center;
      }


    .radiobutton{
      font-size: 1rem;
      display: flex;
      flex-direction: column;
    }
  }
}
}
